<template>
	<div class="relative">
		<img
			:src="userImage"
			alt="Where's Weed user"
			:width="width"
			:height="height"
			class="border border-gray-300 rounded-full shadow-sm"
			@error="setFallbackImage"
		>
		<img
			v-if="shouldShowVerified"
			class="absolute bottom-0 right-0"
			:src="verificationBadgeIcon"
			width="17"
			height="17"
			alt="verified user"
		>
	</div>
</template>

<script defer>
import { USER_IMAGES } from '@/constants/images/image-folder-paths.js'
import buildImageUrl from '@/utils/builders/buildImageUrl.js'

export default {
	props: {
		user: {
			type: Object,
			default: () => ({})
		},
		width: {
			type: Number,
			default: 50
		},
		height: {
			type: Number,
			default: 50
		},
		shouldShowVerified: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		fallbackImage() {
			return require('@/assets/img-defaults/default-profile-icon.jpg')
		},
		userImage() {
			if (!this.user?.avatar?.fileName) return this.fallbackImage
			const imageOptions = {
				imagePath: USER_IMAGES, width: 60, height: 60, file: this.user.avatar.fileName
			}
			return buildImageUrl(imageOptions)
		},
		verificationBadgeIcon() {
			return require('@/assets/icons/star-full-circled-yellow.svg')
		}
	},
	methods: {
		setFallbackImage(event) {
			event.target.src = this.fallbackImage
		}
	}
}
</script>
